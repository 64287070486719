@import "style/index.scss";
.alertMessage {
  position: fixed;
  top: 7rem;
  right: 1rem;
  z-index: index($zIndexs, "default");
  width: 100%;
  max-width: 320px;
  border-radius: 1px;
  min-height: 64px;
  max-height: 800px;
  margin-bottom: 1rem;
  overflow: hidden;
  &.active {
    z-index: index($zIndexs, "dialog");
  }
  .alertMessage_container {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    min-height: 64px;
    color: #fff;
    padding: 8px;
    background-image: linear-gradient(
      to top,
      #ff9a9e 0%,
      #fecfef 99%,
      #fecfef 100%
    );
    &.in {
      animation-name: bounceInRight;
      animation-duration: 1s;
    }
    &.out {
      animation-name: bounceOutRight;
      animation-duration: 1s;
    }
    .alertMessage_content {
      flex: 1;
      margin: auto 0;
      font-weight: 700;
      font-size: 1rem;
      padding: 5px;
      svg {
        color: #fff;
        margin-right: 5px;
      }
      span {
        text-shadow: 1px 1px 1px rgba(255, 100, 100, 0.5);
      }
    }
    button {
      color: #fff;
      font-weight: 700;
      font-size: 14px;
      background: transparent;
      outline: none;
      border: none;
      padding: 0;
      cursor: pointer;
      opacity: 0.7;
      transition: 0.3s ease;
      align-self: flex-start;
      &:hover {
        opacity: 1;
      }
    }
    .alertMessage_bar {
      width: 100%;
      background-color: hsla(0, 0%, 100%, 0.7);
      position: absolute;
      left: 0;
      bottom: 0;
      height: 5px;
      &.in {
        animation-name: running;
        animation-duration: 5s;
      }
      &.out {
        width: 0;
      }
    }
  }
}
@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes running {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
