$desktop-media:1200px;
$pad-media:992px;
$phone-media:767px;

@mixin desktop() {
    @media screen and (min-width: $desktop-media) {
        @content;
    }
}

@mixin padtop(){
    @media screen and (min-width: $pad-media){
        @content;
    }
}
@mixin phonetop() {
    @media screen and (min-width: $phone-media) {
        @content;
    }
}
@mixin phone() {
    @media screen and (max-width: $phone-media) {
        @content;
    }
}




// how to use

// 1.
// import this file into component's index.module.scss

// 2.
// @include phone{
//     padding:20px;
// }
