@import './media.scss';
$main_color:#f00;
$sec_color:#ff0;
$defalt_fontcolor:#ccc;
$main-font: 'Helvetica Neue', 'PingFang TC', 'Heiti TC', '微軟正黑體', sans-serif;
$title-font: 'Helvetica Neue', 'PingFang TC', 'Heiti TC', 'Adobe 繁黑體 Std', 'AdobeFanHeitiStd-Bold', '微軟正黑體', sans-serif;

html, body {
  font-size: 16px;
  font-family: $main-font;
  line-height: 1.75;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  font-family: $title-font;
  line-height: 1.5;
}

.article-content {
    color: #666;
    line-height: 1.5;
    font-size: 18px;
    flex:1;
    display: flex;
    h1, h2, h3, h4, h5, h6 {
        margin-top: 1em;
        margin-bottom: .6em;
    }

    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.7rem;
    }

    h4 {
        font-size: 1.5rem;
    }

    h5 {
        font-size: 1.2rem;
    }

    h6 {
        font-size: 1rem;
    }
    
    p {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    hr {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    pre {
        margin-top: 35px;
        margin-bottom: 35px;
    }
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.container {
    margin: auto;
    @include phone {
        width: 95%;
    }
    @include padtop {
        max-width: 960px;
    }
    @include desktop {
        max-width: 1140px;
    }
}